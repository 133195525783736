import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";

export default ({ data }) => (
    <Layout>
        <div className="blog-main">
            <div className="blog-inner">

                <h1>Om {data.site.siteMetadata.title}</h1>
                <p>
                    En personlig och då och då kontroversiell blog om sådant som intresserar eller irriterar mig.
    </p>
            </div>

        </div>
    </Layout>
);

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`